import styled from 'styled-components';

import { colors } from '../../../styles/atoms/colors';
import { font } from '../../../styles/atoms/typography';
import shadow from '../../../styles/atoms/shadows';

export const Container = styled.div`
  width: 100%;
  height: auto;
  padding-top: 14px;
  .hs-form {
    display: flex;
  }
  .hs-form-field label {
    display: none;
  }
  .input {
    width: fit-content;
  }
  .hs-input {
    ${shadow('xs')}
    width: 100%;
    max-width: 270px;
    ${font('text', 'lg', '400')}
    background: ${colors.base.white};
    color:  ${colors.gray[600]};
    border: 1px solid ${colors.gray[300]};
    border-radius: 8px;
    padding: 10px 14px;
    margin-right: 8px;
    transition: all 200ms ease-in-out;
    &:focus {
      border: 1px solid ${colors.primary[300]};
      ${shadow('focusRing')}
    }
    &.error {
      border: 1px solid ${colors.error[500]};
    }
  }
  .hs-submit {
    height: fit-content;
  }
  .hs-button {
    min-width: 111px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12.5px 16px;
    ${font('text', 'sm', '700')}
    background: ${colors.primary[500]};
    color: ${colors.base.white};
    border: 1px solid ${colors.primary[500]};
    border-radius: 6px;
    margin-left: 8px;
    transition: all 200ms ease-in-out;
    &:hover {
       background-color: ${colors.primary[700]};
    }
  }
  .hs-error-msgs {
    list-style: none;
    padding-top: 16px;
  }
  .hs-error-msg {
    display: block !important;
    ${font('text', 'sm', '600')}
    color: ${colors.error[500]};
  }
  .hs_error_rollup {
    display: none;
  }
  .submitted-message {
    ${font('display', 'sm', '700')}
    color: ${colors.green[600]};
  }

  .input:has(label[for^='contact_me_via_fax']) {
    display: none !important;
  }

  label[for^='contact_me_via_fax'] {
    display: none !important;
  }
`;
